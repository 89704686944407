<template>
  <div class="doubt">
    <div class="doubt-warp">
      <div class="title">关于我们</div>
      <div class="con"><span> 于2019年12月成立，致力于开发电子竞技构建数字内容生态，为用户提供更多了安全的娱乐渠道，使用独特的交易模式，促使steam娱乐更快捷安全的提取到用户得库存，被众多玩家所喜爱。</span><br>
<span>www.gosking.net是国内货涵盖全面的CSGO饰品交易品牌。www.gosking.net网站上，你可以即时获得更多的csgo的物品，www.gosking.net特惠商城有海量的商品可随时取回到库存，其库存量没有任何一家同类型网站可以比拟。<br></span>
<span>联系地址：301-309 Nathan Road, Yau Tsim Mong, Hong Kong</span><br>
<span>联系方式：www.gosking.net@163.com</span><br></div>
    </div>
  </div>
</template>

<script>
export default {

};
</script>

<style lang="less" scoped>
.doubt{
    overflow: hidden;
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    background-color: #1a1c24;

    .doubt-warp{
        width: 1200px;
        margin: 0 auto;
    }
    .title{
        padding: 20px 0;
        font-size: 24px;
        color: #848492;
    }
    .con{
      font-size: 14px;
      color: #848492;
      span{
        display: inline-block;
       margin-top: 10px;
      }
    }
}
</style>
